import axios from "axios";

// const baseAPIURL = 'http://localhost:8000';
const baseAPIURL = "https://api-admin.thebotique.ru"
const basePhotoAPIURL = "https://render-api.thebotique.ru"
const orderAPIURL = "https://orders.thebotique.ru";

const api = {
  setTaxation: async (taxation) => {
    try {
      const response = await axios.put(`${baseAPIURL}/api/v1/update-taxation/`, { taxation: taxation }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  checkOgrn: async (ogrn) => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/check-ogrn/`, {
        params: { OGRN: ogrn },
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getRefresh: async () => {
    try {
      const response = await axios.post(`${baseAPIURL}/api/v1/auth/token/refresh/`,
        { refresh: localStorage.getItem("refresh_token") },
        { headers: { "Content-Type": "application/json" } }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  logoutAPI: async () => {
    try {
      const response = await axios.post(`${baseAPIURL}/api/v1/auth/logout/`,
        { refresh_token: localStorage.getItem("refresh_token") }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  emailConfirm: async (token) => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/auth/confirm/${token}/`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  emailChange: async (data) => {
    try {
      const response = await axios.put(`${baseAPIURL}/api/v1/auth/change/email/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  pinConfirm: async (code) => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/auth/confirm/pin/${code}/`);
      return response;
    } catch (error) {
      throw error;
    }
  },
  getAccessToken: async (userData) => {
    try {
      const response = await axios.post(`${baseAPIURL}/api/v1/auth/login/`, userData,
        { headers: { "Content-Type": "application/json" } }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  getAccessTokenForYandexToken: async (userData) => {
    try {
      const response = await axios.post(`${baseAPIURL}/api/v1/auth/ya/`, userData,
        { headers: { "Content-Type": "application/json" } }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  sendRecoveryEmail: async (email_or_username) => {
    try {
      const response = await axios.post(`${baseAPIURL}/api/v1/auth/reset-password/`, { email_or_username: email_or_username },
        { headers: { "Content-Type": "application/json" } }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  registerAPI: async (userData) => {
    try {
      const response = await axios.post(`${baseAPIURL}/api/v1/auth/register/`, userData,
        { headers: { "Content-Type": "application/json" } }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  // STORES API
  getStoresList: async () => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/stores/stores-list/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getStore: async (storeID) => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/stores/store/${storeID}`, {
        params: { token: localStorage.getItem("_signiture_for_store_data") },
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  createStore: async (data) => {
    try {
      const response = await axios.post(`${baseAPIURL}/api/v1/stores/create-store/`,
        data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateStore: async (storeID, data) => {
    try {
      const response = await axios.put(`${baseAPIURL}/api/v1/stores/store/${storeID}/`,
        { ...data, token: localStorage.getItem("_signiture_for_store_data") },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateStoreTemplateCode: async (storeID, data) => {
    try {
      const response = await axios.put(`${baseAPIURL}/api/v1/stores/change-store-template/${storeID}/`,
        { ...data, token: localStorage.getItem("_signiture_for_store_data") },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  storePrefill: async () => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/stores/store/prefill/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteStore: async (storeID) => {
    try {
      const response = await axios.delete(`${baseAPIURL}/api/v1/stores/store/${storeID}/`, {
        params: { token: localStorage.getItem("_signiture_for_store_data") },
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  // END STORES API

  // BANNERS API
  getBanners: async () => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/stores/store/${localStorage.getItem("current_store_id")}/banners-list`, {
        params: { token: localStorage.getItem("_signiture_for_store_data") },
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getBanner: async (bannerID) => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/stores/store/${localStorage.getItem("current_store_id")}/banner/${bannerID}/`, {
        params: { token: localStorage.getItem("_signiture_for_store_data") },
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  createBanner: async (newData) => {
    try {
      const response = await axios.post(`${baseAPIURL}/api/v1/stores/store/${localStorage.getItem("current_store_id")}/create-banner/`,
        { ...newData, token: localStorage.getItem("_signiture_for_store_data") },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateBanner: async (newData, bannerID) => {
    try {
      const response = await axios.put(`${baseAPIURL}/api/v1/stores/store/${localStorage.getItem("current_store_id")}/banner/${bannerID}/`,
        { ...newData, token: localStorage.getItem("_signiture_for_store_data") },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          }
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteBanner: async (bannerId) => {
    try {
      const response = await axios.delete(`${baseAPIURL}/api/v1/stores/store/${localStorage.getItem("current_store_id")}/banner/${bannerId}/`, {
        params: { token: localStorage.getItem("_signiture_for_store_data") },
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getTODO: async () => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/home/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getHomedata: async () => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/stats-data/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getHomeStats: async (id) => {
    try {
      const response = await axios.post(`${orderAPIURL}/api/v2/stores/${id}/orders/statistics`, JSON.stringify({ days: [7, 30, 730] }), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getNotificationsData: async () => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/notifications/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  changeNotificationsData: async (data) => {
    try {
      const response = await axios.put(`${baseAPIURL}/api/v1/seen/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      return response;
    } catch (error) {
      throw error;
    }
  },
  getProducts: async () => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/stores/store/${localStorage.getItem("current_store_id")}/products/list/`,
        {
          params: { token: localStorage.getItem("_signiture_for_store_data") },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      return response;
    } catch (error) {
      throw error
    }
  },
  getProductsV2: async (page, sort_by, order_by) => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/products/list`,
        {
          params: {
            store_id: localStorage.getItem("current_store_id"),
            token: localStorage.getItem("_signiture_for_store_data"),
            page_size: 20,
            page: page,
            sort_by: sort_by,
            order_by: order_by,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      return response
    } catch (error) {
      throw error
    }
  },
  getStoreProducts: async (storeID) => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/stores/store/${storeID}/products/list/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      return response
    } catch (error) {
      throw error
    }
  },
  getProduct: async (productID) => {
    try {
      const response = await axios.get(
        `${baseAPIURL}/api/v1/stores/store/${localStorage.getItem(
          "current_store_id"
        )}/products/${productID}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      return response
    } catch (error) {
      throw error
    }
  },
  createProduct: async (data) => {
    try {
      const response = await axios.post(
        `${baseAPIURL}/api/v1/stores/store/${localStorage.getItem(
          "current_store_id"
        )}/products/new/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      return response
    } catch (error) {
      throw error
    }
  },
  updateProduct: async (productID, data) => {
    try {
      const response = await axios.put(
        `${baseAPIURL}/api/v1/stores/store/${localStorage.getItem(
          "current_store_id"
        )}/products/${productID}/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      return response
    } catch (error) {
      throw error
    }
  },
  updateProductStateV2: async (productID, data) => {
    try {
      const response = await axios.put(`${baseAPIURL}/api/v1/products/${productID}/set-state/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteProductV2: async (productID) => {
    try {
      const response = await axios.delete(`${baseAPIURL}/api/v1/products/${productID}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      return response
    } catch (error) {
      throw error
    }
  },
  pinProduct: async (productID, store) => {
    try {
      const response = await axios.put(`${baseAPIURL}/api/v1/products/${productID}/pin-product/`,
        {
          store: localStorage.getItem("current_store_id"),
          token: localStorage.getItem("_signiture_for_store_data"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      return response
    } catch (error) {
      throw error
    }
  },
  getCharacteristicsList: async () => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/stores/characteristics/list/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getCategoriesList: async () => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v2/categories/list/`, {
        params: { token: localStorage.getItem("_signiture_for_store_data"), store_id: localStorage.getItem("current_store_id"), },
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getSubcategoriesList: async (categoryID) => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v2/categories/${categoryID}/subcategories/list/`, {
        params: { token: localStorage.getItem("_signiture_for_store_data"), store_id: localStorage.getItem("current_store_id"), },
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateUserPassword: async (data) => {
    try {
      const response = await axios.put(`${baseAPIURL}/api/v1/auth/change-password/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getUserData: async () => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/profile/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateUserData: async (data) => {
    try {
      const response = await axios.put(`${baseAPIURL}/api/v1/profile/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateUserEmailFlag: async (data) => {
    try {
      const response = await axios.put(`${baseAPIURL}/api/v1/change-email-flag/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateTGUserFlag: async (data) => {
    try {
      const response = await axios.put(`${baseAPIURL}/api/v1/change-tg-flag/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  changeTariff: async (status) => {
    try {
      const response = await axios.put(`${baseAPIURL}/api/v1/auth/update-subscription-status/`,
        { subscription_type: status },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  cancelTariff: async () => {
    try {
      const response = await axios.put(`${baseAPIURL}/api/v1/auth/cancel-subscription/`, {}, {
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  deleteUser: async () => {
    try {
      const response = await axios.delete(`${baseAPIURL}/api/v1/auth/seller/delete/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  sendEmail: async (data) => {
    try {
      const response = await axios.post(`${baseAPIURL}/api/v1/send-email/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  createTask: async (data) => {
    try {
      const response = await axios.post(`${baseAPIURL}/api/v1/create-task/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  updateTask: async (instructionID, data) => {
    try {
      const response = await axios.put(`${baseAPIURL}/api/v1/task/${instructionID}/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
      return response;
    } catch (error) { throw error; }
  },
  getQRcode: async (data) => {
    try {
      const response = await axios.post(`${baseAPIURL}/api/v1/generate_qr_code/`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
      return response;
    } catch (error) { throw error; }
  },
  uploadImg: (formData) => {
    try {
      const config = {
        method: "post",
        url: `${basePhotoAPIURL}/api/v1/media/upload`,
        headers: {
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
        data: formData,
      };
      const response = axios(config);
      return response;
    } catch (error) { throw error; }
  },

  // OLD ORDERS
  getOrders: async (storeID) => {
    try {
      const response = await axios.get(`https://orders-storage.thebotique.ru/api/v1/stores/${storeID}/orders`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getOrder: async (storeID, orderID) => {
    try {
      const response = await axios.get(`https://orders-storage.thebotique.ru/api/v1/stores/${storeID}/orders/${orderID}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  // Order status API
  getAllStatuses: async (orderID, userID) => {
    try {
      const response = await axios.get(
        `https://status-tracker.thebotique.ru/api/v1/order/${orderID}/status?userID=${userID}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      return response
    } catch (error) {
      throw error
    }
  },
  getLastStatus: async (data) => {
    try {
      const response = await axios.post(
        `https://status-tracker.thebotique.ru/api/v1/status/last`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      return response
    } catch (error) {
      throw error
    }
  },
  loadProductsFromFileV2: async (formData) => {
    try {
      const response = await axios.post(`${baseAPIURL}/api/v1/products/upload-template/`,
        // const response = await axios.post(`http://localhost:8000/api/v1/products/upload-template/`,
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      return response
    } catch (error) {
      throw error
    }
  },
  setOrderStatus: async (data) => {
    try {
      const response = await axios.post(
        `https://status-gateway.thebotique.ru/api/v1/status/set`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        }
      )
      return response
    } catch (error) {
      throw error;
    }
  },
  // END OLD STATUS API

  checkTelegramToken: async (token) => {
    try { return await axios.get(`https://api.telegram.org/bot${token}/getMe`); } catch (error) { throw error; }
  },
  checkBoxberryToken: async (token) => {
    try {
      const response = await axios.post('https://delivery.thebotique.ru/api/v1/token/verify', { token: token },
        {
          headers: { "Content-Type": "application/json", },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  // ORDERS v2
  getOrdersStatistics: async (storeID) => {
    try {
      const response = await axios.post(`${orderAPIURL}/api/v2/stores/${storeID}/orders/statistics`, JSON.stringify({ days: [730] }), {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
        },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getOrdersv2: async (storeID, search, page, size, sortBy, orderBy) => {
    try {
      const response = await axios.get(`https://orders.thebotique.ru/api/v1/stores/${storeID}/orders/search`, {
        params: { size: size, page: page, search: search, sort_by: sortBy, order_by: orderBy ? orderBy : '' },
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getOrderv2: async (orderID) => {
    try {
      const response = await axios.get(`https://orders.thebotique.ru/api/v1/orders/${orderID}`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getOrderReview: async (orderID) => {
    try {
      return await axios.get(`https://order-evaluation.thebotique.ru/api/v1/order/evaluations/${orderID}`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });

    } catch (error) {
      throw error;
    }
  },
  // Statuses API
  confirmStatus: async (orderID) => {
    try {
      const response = await axios.post(`https://orders.thebotique.ru/api/v1/orders/confirm`, { order_id: orderID }, {
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), "Content-Type": "application/json", },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  completeStatus: async (orderID) => {
    try {
      const response = await axios.post(`https://orders.thebotique.ru/api/v1/orders/complete`, { order_id: orderID }, {
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), "Content-Type": "application/json", },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getOrderInfoImg: async (card_id) => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/products/get-card-photo`, {
        params: { card_id: card_id },
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  getStoreSign: async (storeID) => {
    try {
      const response = await axios.get(`${baseAPIURL}/api/v1/stores/get-signiture`, {
        params: { store: storeID },
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  // Payment info
  getShopID: async (storeID) => {
    try {
      return await axios.get(`https://tinkoff-payments.thebotique.ru/api/v1/points/short/shop/${storeID.slice(0, 18)}`).catch((e) => {
        console.log(e.response);
      });
    } catch (error) {
      throw error;
    }
  },
  // Payment register
  registerShop: async (data) => {
    try {
      return await axios.post(`https://tinkoff-payments.thebotique.ru/api/v1/points/register`, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
        },
      }).catch((e) => {
        console.log(e.response);
      });
    } catch (error) {
      throw error;
    }
  },

  // Subscriptions api
  getPricing: async () => {
    try {
      return await axios.get(`${baseAPIURL}/api/v1/pay/pricing/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token") },
      }).catch((e) => { console.log(e.response); });
    } catch (error) {
      throw error;
    }
  },
  changeTariffv2: async (subscriptionType, periodParam) => {
    try {
      return await axios.put(`${baseAPIURL}/api/v1/pay/change-tariff/`, { subscription_type: subscriptionType, periods: periodParam }, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
          "Content-Type": "application/json",
        },
      }).catch((e) => {
        console.log(e.response);
      });
    } catch (error) {
      throw error;
    }
  },
  // Referral API
  generateReferralCode: async () => {
    try {
      return await axios.post(`${baseAPIURL}/api/v1/auth/generate-referral-code/`, {}, {
        headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), },
      }).catch((e) => { console.log(e.response); });
    } catch (error) {
      throw error;
    }
  },
}

export default api