
import React, { useState } from "react";
import { v4 as uuid } from 'uuid';
import infoImg from '../assets/info-icon.svg';
import arrowUp from "../assets/icons8-шеврон-вверх-30.png";
import arrowDown from "../assets/icons8-шеврон-вниз-30.png";
import styles from "../styles/CategoriesSubcategoriesComponent.module.css";
import CustomCheckBox from "./CustomCheckBoxComponent.js";

function CategoriesSubcategoriesComponent({ categories, setCategories, handleCategoryCheckBoxChange, handleSubCategoryCheckBoxChange, createCategoryRequest, createSubCategoryRequest }) {
    const [expandedCategories, setExpandedCategories] = useState({});
    const [newCategory, setNewCategory] = useState("");
    const [newSubCategory, setNewSubCategory] = useState({ categoryId: null, name: "" });
    const [showSubCategoriesInfo, setShowSubCategoriesInfo] = useState(false);

    const toggleExpandCategory = (categoryId) => {
        setExpandedCategories(prev => ({
            ...prev,
            [categoryId]: !prev[categoryId]
        }));
    };

    const handleAddCategory = () => {
        if (newCategory.trim() === "") return;
        createCategoryRequest(newCategory).then(({ data }) => {
            setCategories(prev => [...prev, { id: data.id, name: newCategory, state: true, custom: true, subcategories: [{ id: data.subcategory_id, name: "Разное", state: true }] }])
            setNewCategory("");
        });

    };

    const handleAddSubCategory = () => {
        if (newSubCategory.name.trim() === "" || newSubCategory.categoryId === null) return;
        
        createSubCategoryRequest(newSubCategory.name, newSubCategory.categoryId).then(({ data }) => {
            setCategories(prev => prev.map(cat => {
                if (cat.id === newSubCategory.categoryId) {
                    return {
                        ...cat,
                        subcategories: [...cat.subcategories, { id: data.id, name: newSubCategory.name, state: true }]
                    };
                }
                return cat;
            }));
            setNewSubCategory({ categoryId: null, name: "" });
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.InnerContainer}>
                <div className={styles.containerTitle}>
                    <span>Управление категориями/подкатегориями</span>
                    <span>Выберите категории и подкатегории, которые будут отображаться в форме товара. Также Вы можете добавить категории и подкатегории, которых Вам не хватает.</span>
                </div>
                <div className={styles.catgoriesSubcategroiesList}>
                    {categories.map(category => (
                        <div key={category.id} className={styles.categoryContainer}>
                            <div className={styles.category}>
                                <CustomCheckBox
                                    checked={category.state}
                                    onChange={() => handleCategoryCheckBoxChange(category.id)}
                                />
                                <span className={styles.categoryName}>{category.name}</span>
                                <img
                                    className={styles.toggleButton}
                                    src={expandedCategories[category.id] ? arrowUp : arrowDown}
                                    onClick={() => toggleExpandCategory(category.id)}
                                >

                                </img>
                            </div>

                            {expandedCategories[category.id] && (
                                <div className={styles.subcategoriesContainer}>
                                    {category.subcategories.map(subcategory => (
                                        <div key={subcategory.id} className={styles.subcategory}>
                                            <CustomCheckBox
                                                checked={category.state ? subcategory.state : false}
                                                disabled={!category.state || category.subcategories.length < 2}
                                                onChange={() => handleSubCategoryCheckBoxChange(category.id, subcategory.id)}
                                            />
                                            {subcategory.name}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className={styles.addCategory}>
                    <div className={styles.addCategoryContainer}>
                        <span className={styles.addCategoryText}>Добавление категории</span>
                    </div>

                    <div className={styles.newCategoryInput}>
                        <input
                            type="text"
                            value={newCategory}
                            onChange={(e) => { e.target.value.length <= 64 && setNewCategory(e.target.value) }}
                            placeholder="Категория"
                            onKeyUp={({ key }) => {
                                if (key === 'Enter') {
                                    handleAddCategory();
                                }
                            }}
                        />
                        <button onClick={handleAddCategory} className={styles.addCategoryBtn}></button>
                    </div>

                </div>
                <div className={styles.addSubCategory}>
                    <div className={styles.addCategoryContainer}>
                        <div>
                            <span>Добавление подкатегории </span>
                            <span onMouseEnter={() => setShowSubCategoriesInfo(true)} onMouseLeave={() => setShowSubCategoriesInfo(false)}>
                                <img className={styles.infoImg} src={infoImg} alt="" />
                                {showSubCategoriesInfo && (
                                    <div className={styles.notificationBlock}>
                                        <p>Добавление подкатегорий доступно только для добавленных Вами категорий</p>
                                    </div>
                                )}
                            </span>
                        </div>
                        <select
                            className={styles.addSubCategoryDropdown}
                            value={newSubCategory.categoryId || ""}
                            onChange={(e) => setNewSubCategory({ ...newSubCategory, categoryId: e.target.value })}
                        >
                            <option value="" disabled>Выберите категорию</option>
                            {categories.map(category => (
                                category.custom && <option key={category.id} value={category.id}>{category.name}</option>
                            ))}
                        </select>
                        <input
                            type="text"
                            value={newSubCategory.name}
                            className={styles.addSubCategoryInput}
                            onChange={(e) => { e.target.value.length <= 32 && setNewSubCategory({ ...newSubCategory, name: e.target.value }) }}
                            placeholder="Подкатегория"
                            onKeyUp={({ key }) => {
                                if (key === 'Enter') {
                                    handleAddSubCategory();
                                }
                            }}
                        />
                    </div>

                    <button onClick={handleAddSubCategory} className={styles.addCategoryBtn}></button>
                </div>
            </div>
        </div>
    );
}

export default CategoriesSubcategoriesComponent;
