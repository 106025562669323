
import React, { useEffect, useRef, useState } from "react";
import plus from '../../../assets/icon add-3.png';
import Loading from '../../../components/Loading';
import { error, success } from "../../../components/notification";
import api from "../api/api";
import styles from "./MarketingComponent.module.css";
import MarketingHistoryComponent from "./MarketingHistoryComponent";
import Table from "./TableComponent";


const MarketingComponent = () => {

    function sortObjectsByProperty(objects, property) {
        return objects.sort(function (a, b) {
            if (a[property] === undefined || b[property] === undefined) {
                return -1; // Помещаем объекты с отсутствующим свойством вниз
            }

            var valueA = a[property].toUpperCase();
            var valueB = b[property].toUpperCase();

            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            return 0;
        });
    }

    function sortObjectsByDateDescending(array) {
        array.sort(function (a, b) {
            return new Date(b.updated_at) - new Date(a.updated_at);
        });
        return array;
    }



    const [usersData, setUsersData] = useState([]);
    const [allUserData, setAllUserData] = useState([]);
    const [text, setText] = useState('');
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [shortCutLink, setShortCutLink] = useState('');
    const [shortCutUsername, setShortCutUsername] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [marketingHistory, setMarketingHistory] = useState({ mailings: [] });
    const [isHistoryLoading, setIsHistoryLoading] = useState(false);
    const inputRef = useRef(null);
    const [flagSortByNames, setFlagByNames] = useState(false);
    const [page, setPage] = useState(1);
    const [flagSortByDate, setFlagByDate] = useState(false);

    const sortByNamesUserData = () => {
        setFlagByDate(false);
        if (flagSortByNames === true) {
            setUsersData(allUserData);
            setFlagByNames(false);
            return
        }
        setUsersData(sortObjectsByProperty(allUserData, "username"));
        setFlagByNames(true);
    };

    const sortByDateUserData = () => {
        setFlagByNames(false);
        if (flagSortByDate === true) {
            setUsersData(allUserData);
            setFlagByDate(false);
            return
        }
        setUsersData(sortObjectsByDateDescending(allUserData));
        setFlagByDate(true);
    };

    const handleImageChange = (e) => {
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
        const maxFileSize = 2 * 1024 * 1024;
        if (inputRef) {
            const file = inputRef.current.files[0];
            if (file && allowedTypes.includes(file.type) && file.size <= maxFileSize) {
                setSelectedPhoto({ file: file, url: URL.createObjectURL(file) });
            } else {
                error('Выбранное фото не соответствует требованиям!');
            }
        }
    };

    const fileToByteString = (file) => {
        if (!file) {
            return new Promise((resolve, reject) => { resolve(''); });
        }

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result.split(',')[1];
                resolve(base64String);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    };


    useEffect(() => {
        api.getCustomers().then(r => {
            var usersData = r.data;
            api.getCarts().then(r => {
                usersData = usersData.map(el => {
                    const cartData = r.data[el.user_id] || { cart_items_count: 0, products: [] };
                    return {
                        ...el,
                        cart_items_count: cartData.cart_items_count,
                        products: cartData.products,
                        selected: false
                    };
                });
                setUsersData(usersData);
                setAllUserData(usersData);
            });
        });

        api.getShortCuts().then(r => {
            if (r?.data) {
                setShortCutLink(r.data?.link);
                setShortCutUsername(r.data?.username);
            }
        });

        setIsHistoryLoading(true);
        api.getHistory().then(r => {
            setMarketingHistory(r.data);
            setIsHistoryLoading(false);
        });

        document.body.classList.add(styles.bodyBlock);

        return () => {
            document.body.classList.remove(styles.bodyBlock);
        }
    }, []);

    const getGreeting = () => {
        const currentHour = new Date().getHours();
        if (currentHour < 12) return "Доброе утро! Мы рады видеть вас! ";
        if (currentHour < 18) return "Добрый день! У нас есть отличные предложения для вас. ";
        return "Добрый вечер! Надеемся, у вас был прекрасный день. ";
    };

    const addText = (newText) => {
        setText((prevText) => prevText + newText);
    };

    const send = () => {
        if (text !== '') {
            const selectedUsers = usersData.filter(el => el.selected);
            if (selectedUsers.length > 0) {
                setIsLoading(true);
                fileToByteString(selectedPhoto?.file).then(byteString => {
                    api.sendMailing(text, byteString, selectedUsers.map(user => ({ user_id: user.user_id, username: user.username }))
                    ).then(r => {
                        setText('');
                        setSelectedPhoto(null);
                        setUsersData(usersData.map(el => ({ ...el, selected: false })));
                        success('Рассылка отправлена');
                        setIsLoading(false);
                    });
                })


            } else {
                error('Выберите пользователей для отправки');
            }
        } else {
            error('Нельзя отправить рассылку без текста!');
        }
    };

    const loadHistory = () => {
        setPage(prev => prev + 1);
        setIsHistoryLoading(true);
        api.getHistory(page).then(r => {
            setMarketingHistory(prev => ({ ...prev, mailings: [...prev.mailings, ...r.data.mailings] }));
            setIsHistoryLoading(false);
        });
    };





    return (
        <div className={styles.megaContainer}>
            <div className={styles.container}>
                <h1 className={styles.title}>Клиенты и рассылки</h1>
                <div className={styles.marketingBlocksContainer}>
                    <div className={styles.marketingBlocksContainerTitle}>Новая рассылка</div>
                    <div className={styles.marketingBlocks}>
                        <div>
                            <label className={styles.chooseImg} htmlFor="fileInput">
                                {
                                    selectedPhoto === null ?
                                        <div ref={inputRef} className={styles.emptyInput}>
                                            <img src={plus} alt=""></img>
                                            <span>Добавить фото (до 2 Мб)</span>
                                        </div>
                                        : <div ref={inputRef}><img className={styles.choosenImg} src={selectedPhoto.url} alt="Изображение" /></div>
                                }
                            </label>
                            <input type="file" ref={inputRef} id="fileInput" onChange={handleImageChange} style={{ display: "none" }} />
                        </div>
                        <div className={styles.marketingBlockContainer}>
                            <div className={styles.quickButtons}>
                                <button onClick={() => addText(getGreeting())}>Привет!</button>
                                <button onClick={() => addText(`У нас появился новый товар, который может вас заинтересовать: ${shortCutLink} `)}>Новый товар</button>
                                <button onClick={() => addText(`Не знаете, что выбрать? Наши специалисты всегда готовы помочь!\nНапишите нашему менеджеру: @${shortCutUsername} `)}>Нужна помощь?</button>
                            </div>
                            <textarea className={styles.marketingBlockText} placeholder="Введите текст рассылки" value={text} onChange={(e) => { setText(e.target.value); }}></textarea>
                        </div>
                        {usersData.length > 0 && <Table usersData={usersData} setUsersData={setUsersData} handlerSortByDate={sortByDateUserData} handlerSortByName={sortByNamesUserData} />}
                    </div>
                </div>
                {usersData.length > 0 ? <button className={styles.sendBtn} onClick={send}>Отправить рассылку</button> : <span>Посетители магазина отсутствуют - отправка рассылок недоступна</span>}

                <div style={{ width: '100%' }}>
                    <div className={styles.marketingBlocksContainerTitle}>История рассылок</div>
                </div>
                {marketingHistory.mailings.length > 0 && (
                    <div className={styles.historyContainer}>
                        {marketingHistory.mailings.map((item, id) => (
                            <MarketingHistoryComponent key={id} data={item} />
                        ))}
                    </div>
                )}
                {isHistoryLoading ?
                    <div className={styles.historyLoader}></div> :
                    <button className={styles.sendBtn} onClick={loadHistory}>Загрузить еще</button>
                }
            </div>
            {isLoading && <Loading />}
        </div>
    );
};
export default MarketingComponent;
